.App {
  text-align: center;
  background-color: #ffffff;
  /* margin-left: 4px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  top: 0px;
  position: fixed;
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  box-shadow: 10px 10px 30px rgba(172, 172, 172, 0.25);
  z-index: 999;
}

.App-body {
  height: calc(100vh - 118px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  margin-top: 78px;

  .App-content-full {
    margin-left: 0px;
  }
}

.App-leftnav {
  position: fixed;
  left: 0px;
  box-shadow: 10px 10px 30px rgba(172, 172, 172, 0.25);
  height: 100vh;
  padding-top: 42px;
  z-index: 99;
}

.App-content {
  // min-height: calc(100vh - 98px);
  width: calc(100% - 88px);
  height: 100%;
  resize: both;
  background: #ffffff;
  margin-left: 88px;
  color: black;
  display: flex;
  // flex: 1 0 auto;
  flex-direction: column;
}

// .App-content-full {
//   margin-left: 0px;
//   // width: 100%;
// }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-footer {
  width: 100%;
  position: fixed;
  height: 40px;
  left: 0px;
  bottom: 0px;
  font-weight: normal;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 10px -10px 30px rgba(172, 172, 172, 0.25);
  border-radius: 0px;
  z-index: 999;

  .footer-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 768px) {
  .App-leftnav {
    display: none;
    z-index: 99;
  }

  .App-content {
    width: 100%;
    margin-left: 0px;
  }

  .App-header {
    height: 45px;
    z-index: 999;
  }

  .App-body {
    margin-top: 45px;
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.2);
  border-radius: 20px;
  border: transparent;
}
