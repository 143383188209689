.icon-bar {
  width: 88px; /* Set a specific width */
  /* background-color: #fff;  */
  /* margin-top: 5px; */
  a {
    text-decoration: none;
    color: #000;
  }
  ul {
    padding: 0px;
    list-style-type: none;
    li {
      height: 70px;
    }
  }
}
.respon-icon-bar {
  display: none;
  height: 45px;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
}

.active-icon {
  // color: red;
  > div {
    border-left: 3px solid red;
    background-color: #f0f0f0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.icon-bar .icon {
  padding: 10px;
  transition: all 0.3s ease; /* Add transition for hover effects */
  /* color: white;  */
  font-size: 12px; /* Increased font-size */
}

.icon-bar .icon:hover {
  // opacity: 0.6;
  background-color: #eaeaea;
  // color: red; /* Add a hover color */
}

// .active {
//   color: #04aa6d; /* Add an active/current color */
// }

.icon-bar .icon-text {
  /* text-align: left; */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .icon-bar {
    width: 100%; /* Set a specific width */

    .icon {
      display: flex;
      align-items: center;

      .icon-text {
        padding-left: 20px;
      }
    }
  }

  .respon-icon-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .respon-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
}
.ProdConfigIcon .MuiBadge-badge {
  top: 1px;
  right: -6px;
  opacity: 0.9;
}
