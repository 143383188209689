.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  padding-left: 15px;
  padding-right: 15px;
}
.ham-menu {
  display: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.client-logo {
  // padding: 20px;
  display: flex;
  align-items: center;
  width: 210px;
  height: 59.23px;
}

.client-logo-img {
  width: 210px;
  height: 59.23px;
  cursor: pointer;
}
.gs-logo-profile {
  // padding: 20px;
  display: flex;
}
.gs-logo {
  width: 158px;
  height: 38px;
  align-self: center;
  margin-left: 25px;
}
.gs-profile {
  width: 178px;
}

.right-flex {
  justify-self: flex-end;
}

@media only screen and (max-width: 768px) {
  .ham-menu {
    display: block;
    padding-left: 10px;
    flex-basis: 24px;
  }
  .header-bar {
    padding: 0;
  }

  .client-logo {
    .client-logo-img {
      width: 108px;
      height: 30.46px;
    }
  }
  .gs-logo {
    width: 93px;
    height: 22px;
    margin-left: 5px;
  }
  .gs-profile {
    width: 60px;
  }
}
