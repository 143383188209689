.profile-name-text {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 6px;
}

.down-caret {
  color: #ff5300;
}
.menu-link {
  //   a {
  text-decoration: none;
  color: #000;
  //   }
}
.image-circle {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-url {
  width: 46px;
  height: 46px;
}

.image-icon {
  width: 30px;
  height: 30px;
}

.filterRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: #00000014 1px solid;
  height: 4rem;
}

.filterDetails {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  :hover {
    cursor: pointer;
  }
}

.filterContainer {
  min-height: 10rem;
  max-height: 50rem;
  overflow-y: auto;
  min-width: 25rem;
}

.CloseButton {
  position: absolute !important;
  right: -1% !important;
  top: -2.5% !important;
  background-color: white !important;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.5) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alertMessage {
  color: #ff5300;
  font-size: 0.8rem;
  font-weight: 400;
}

.fadeIn {
  animation: fadeIn 0.5s;
}

@media only screen and (max-width: 768px) {
  .profile-name-text {
    display: none;
    margin-left: 0px;
  }
  .image-circle {
    width: 25px;
    height: 25px;
  }
  .image-url {
    width: 25px;
    height: 25px;
  }
  .image-icon {
    width: 16px;
    height: 16px;
  }
}
